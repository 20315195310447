<template>
  <el-dialog :title="title" v-model="showDialog" :close-on-click-modal="false">
    <h3>{{$t("dialogs.deleteUser.password")}}</h3>
    <el-form @submit.prevent>
      <el-form-item>
        <el-input v-model="password" :type="type" ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss">{{$t("buttons.cancel")}}</el-button>
        <el-button type="primary" @click="onDeleteConfirm">{{$t("buttons.confirm")}}</el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "SimpleInputDialog",
  emits: ["submit", "dismiss"],
  data() {
    return {
      showDialog: false,
      password: "",
      title: "",
      type: "password"
    }
  },
  computed:{
    ...mapGetters(["currentUser"])
  },
  methods: {
    ...mapActions(['deleteUser']),
    show(){
      this.showDialog = true;
    },
    submit() {
      this.$emit("submit", this.formData);
      this.showDialog = false;
    },
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    },
    async onDeleteConfirm(){
        let data = {
            "id": this.currentUser.id,
            "password":this.password
        }
        this.deleteUser(data);
    }
  }

}
</script>

<style scoped>

</style>

