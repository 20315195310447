<template>
  <el-dialog :title="title" v-model="showDialog" :close-on-click-modal="false">
    <el-upload
        class="upload-demo"
        drag
        action=""
        :on-preview="handlePreview"
        :on-change="handleChange"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :auto-upload="false"
        :before-upload="beforeLogoUpload"
        multiple
        :limit="1"
        :on-exceed="handleExceed"
        accept=".jpg, .png"
        ref="upload">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">Trascina il file qui o <em>clicca per importare</em></div>
      <template #tip>
        <div class="el-upload__tip">
        </div>
      </template>
    </el-upload>
    <el-button v-if="uploadType !=='' && file!=null"
               type="primary"
               @click="submitUpload"
    >
      Upload
    </el-button>
  </el-dialog>
</template>

<script>
import {mapActions} from "vuex";
import UserMixin from "../../mixins/UserMixin";
import AddressMixin from "../../mixins/AddressMixin";
import FileMixin from "../../mixins/FileMixin";

export default {
  name: "SimpleImgUploadDialog",
  mixins: [UserMixin, AddressMixin, FileMixin],
  emits: ["submit", "dismiss"],
  data() {
    return {
      showDialog: false,
      file: null,
      title: "",
    }
  },
  methods: {
    ...mapActions(["updateImgData"]),
    show() {
      this.showDialog = true;
    },
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    },
    async submitUpload() {
      const base64 = await this.readIMGFile(this.file.raw)
      await this.updateImgData({"imgData": base64});
      this.handleSuccess();
    },
    handleRemove() {
      this.file = null
      this.$refs.upload.clearFiles()
    },
    handlePreview(/*file*/) {
      //console.log(file)
    },
    handleChange(file) {
      this.file = file;
      this.beforeLogoUpload(this.file);
    },
    handleSuccess() {
      this.$message.success('Logo aggiornato correttamente');
      this.dismiss();
    },
    beforeLogoUpload(file) {
      const isJPEG = file.raw.type === 'image/jpeg'
      const isJPG = file.raw.type === 'image/jpg'
      const isPNG = file.raw.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPEG && !isJPG && !isPNG) {
        this.handleRemove();
        this.$message.error('Il formato del logo deve essere JPG o PNG.')
      }

      // bisogna capire il limite di MB
      if (!isLt2M) {
        this.handleRemove();
        this.$message.error('Le dimensioni del logo non possono superare i 2MB!')
      }
    },
    handleExceed() {
      this.$message.warning(
          `Puoi caricare un solo Logo.`,
      )
    },
  }
}
</script>

<style scoped>

</style>