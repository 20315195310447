<template>
  <el-dialog :title="title" v-model="showDialog" :close-on-click-modal="false">
    <el-form @submit.prevent>
      <el-form-item>
        <el-input v-model="formData" :type="type" step="0.01"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss">Annulla</el-button>
        <el-button type="primary" @click="submit">Conferma</el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script>
export default {
  name: "SimpleInputDialog",
  emits: ["submit", "dismiss"],
  data() {
    return {
      showDialog: false,
      formData: "",
      title: "",
      type: "text"
    }
  },
  methods: {
    show(){
      this.showDialog = true;
    },
    submit() {
      this.$emit("submit", this.formData);
      this.showDialog = false;
    },
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    }
  }

}
</script>

<style scoped>

</style>

